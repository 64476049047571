import { makeStyles } from "tss-react/mui";

export const useImpressumTemplateStyles = makeStyles({
  name: "ImpressumTemplate",
})((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& h2,h3": {
      textAlign: "center",
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(1),
    },
    "& p": {
      textAlign: "center",
      wordBreak: "break-word",
    },
    "& .wp-image-794": {
      width: "140px",
      marginTop: "10px",
    },
    "& h3": {
      marginTop: theme.spacing(0.5),
    },
    "& h2": {
      marginTop: theme.spacing(1),
    },
  },
  nkLogoStyles: {
    "& .gatsby-image-wrapper": {
      marginBottom: theme.spacing(1),
    },
    "& h2": {
      textAlign: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    "& h3": {
      textAlign: "center",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}));
