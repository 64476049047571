import { Container } from "@mui/material";
import { ImpressumTemplate } from "../04-templates/ImpressumTemplate/ImpressumTemplate";

const ImpressumPage = (): JSX.Element => {
  return (
    <Container maxWidth="lg">
      <ImpressumTemplate />
    </Container>
  );
};

export default ImpressumPage;
