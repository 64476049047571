import { Box, Typography } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import { staticSeoTexts } from "../../../data/staticSeoTexts";
import { replaceHtmlWithFunctionComponent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/replaceHtmlWithFunctionComponent";
import { mapWpContent } from "../../../helpers/general/parseWpHtmlToReact/htmlTagsToReactComponents";
import { SeoBasics } from "../../01-atoms/SeoBasics/SeoBasics";
import { ImpressumTemplateProps } from "./ImpressumTemplateInterfaces";
import { useImpressumTemplateStyles } from "./ImpressumTemplateStyles";

export const ImpressumTemplate = (
  props: ImpressumTemplateProps
): JSX.Element => {
  const { classes, cx } = useImpressumTemplateStyles();

  const data = useStaticQuery<GatsbyTypes.ImpressumDataQuery>(graphql`
    query ImpressumData {
      impressum: allWpPage(filter: { id: { eq: "cG9zdDoyNjA=" } }) {
        nodes {
          content
        }
      }
    }
  `);

  // console.log(data);

  const impressumContent = data.impressum.nodes[0].content;

  // console.log(impressumContent);
  return (
    <Box className={cx(classes.wrapper, props.className)}>
      <SeoBasics {...staticSeoTexts.impressum} />

      <Typography variant="h1">Impressum</Typography>

      <Typography variant="body" className={classes.nkLogoStyles}>
        {replaceHtmlWithFunctionComponent(impressumContent, mapWpContent)}
      </Typography>
    </Box>
  );
};
