import { SeoBasicsProps } from "../components/01-atoms/SeoBasics/SeoBasicsInterfaces";

export const staticSeoTexts: Record<string, SeoBasicsProps> = {
  startseite: {
    title: "politikjam – Jugendliche. Journalismus. Jam.",
    description:
      "politikjam ist eine Online-Redaktion von Jugendlichen für Jugendliche. Wir lieben Journalismus, Politik und sind ein Projekt des Jugendpresse Deutschland e.V.",
  },
  aboutUs: {
    description:
      "Ob in journalistischen Workshops, in Redaktionssitzungen oder durch Unterstützung deiner Recherchen – wir helfen dir dabei deinen Beitrag zu erarbeiten.",
    title: "Wir sind eure politikjam-Crew	",
  },
  impressum: {
    description:
      "Hier findest du die presserechtlich Verantwortlichen von politikjam und die Adresse des Jugendpresse Deutschland e.V.",
    title: "Das ist das Impressum von politikjam",
  },
  autorInnen: {
    description:
      "Ob als festes Redaktionsmitglied oder gelegentliche*r Content Creator, ob in Form eines Artikels, Videos oder Podcasts – du bestimmst über die Inhalte von politikjam.",
    title: "Bei politikjam bestimmst du was Thema ist",
  },
  datenschutz: {
    description:
      "Mit dieser Datenschutzerklärung informieren wir dich und klären dich über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten („Daten“) innerhalb unseres Online-Angebots bestehend aus allen Websites betrieben durch die Jugendpresse Deutschland e.V. auf.",
    title: "Dies sind die Datenschutzrichtlinien von politikjam",
  },
};
